import classNames from 'classnames'
import cs from 'classnames'
import clsx from 'classnames'

export function BlurSvg({
    colors = ['#e2ff2399', '#ff6c6c99', '#ff17c999'],
    className = '',
}) {
    let opacity = 0.5
    let w = 800
    let start = 100
    let middle = w / 2
    let end = w - 100
    let blur = 100
    let [cs, cm, ce] = colors
    let radius = 120
    return (
        // gradient on black looks bad if too noticeable
        <div className={classNames('dark:opacity-50', className)}>
            <svg
                xmlns='http://www.w3.org/2000/svg'
                version='1.1'
                xmlnsXlink='http://www.w3.org/1999/xlink'
                className={clsx(
                    'w-full origin-top mx-auto lg:scale-100 dark:saturate-200',
                    'dark:brightness-150 scale-[150%]',
                )}
                style={{ opacity }}
                viewBox={`0 0 ${w} 650`}
            >
                <defs>
                    <filter
                        id='bbblurry-filter'
                        x='-100%'
                        y='-100%'
                        width='400%'
                        height='400%'
                        filterUnits='objectBoundingBox'
                        primitiveUnits='userSpaceOnUse'
                        colorInterpolationFilters='sRGB'
                    >
                        <feGaussianBlur
                            stdDeviation={blur}
                            x='0%'
                            y='0%'
                            width='100%'
                            height='100%'
                            in='SourceGraphic'
                            edgeMode='none'
                            result='blur'
                        />
                    </filter>
                </defs>
                <g filter='url(#bbblurry-filter)'>
                    <ellipse
                        rx={radius * 0.8}
                        ry={radius * 0.8}
                        cx={start}
                        cy='172.91720581054688'
                        fill={cs}
                    />
                    <ellipse
                        rx={radius}
                        ry={radius}
                        cx={middle}
                        cy='0'
                        fill={cm}
                    />
                    <ellipse
                        rx={radius * 0.8}
                        ry={radius * 0.8}
                        cx={end}
                        cy='172.91720581054688'
                        fill={ce}
                    />
                </g>
            </svg>
        </div>
    )
}

export function GradientBg() {
    return (
        <>
            <style jsx global>{`
                .background_main__HFlNS {
                    width: 100vw;
                    min-height: 100vh;
                    position: absolute;
                    display: flex;
                    justify-content: center;
                    padding: 120px 24px 160px;
                    pointer-events: none;
                }

                // .background_main__HFlNS:before {
                //     background: radial-gradient(
                //         circle,
                //         rgba(2, 0, 36, 0) 0,
                //         #111 100%
                //     );
                //     position: absolute;
                //     content: '';
                //     // z-index: 2;
                //     width: 100%;
                //     height: 100%;
                //     top: 0;
                // }

                // .background_main__HFlNS:after {
                //     content: '';
                //     background-image: url(/_static/grid.svg);
                //     // z-index: 1;
                //     position: absolute;
                //     width: 100%;
                //     height: 100%;
                //     top: 0;
                //     opacity: 0.4;
                //     filter: invert(1);
                // }

                .background_content__pv7b5 {
                    // height: -webkit-fit-content;
                    // height: -moz-fit-content;
                    // height: fit-content;
                    // z-index: 3;
                    transform: translateX(0px) translateY(0px) translateZ(0px);
                    max-width: 1800px;
                    background-image: radial-gradient(
                        at 30% 10%,
                        #1b72f5 0,
                        transparent 100%
                    );
                    position: absolute;
                    content: '';
                    width: 100%;
                    height: 40%;
                    filter: blur(100px) saturate(150%);
                    top: 80px;
                    opacity: 0.13;
                }
            `}</style>
            <div className='background_main__HFlNS'>
                <div className='background_content__pv7b5' />
            </div>
        </>
    )
}
