import splitbee from '@splitbee/web'
import {
    Modal,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    useDisclosure,
    Input,
} from '@nextui-org/react'
import { createInviteLinkAuth } from '@/pages/api/invites'
import { motion } from 'framer-motion'

import { Button, useThrowingFn } from 'beskar/src/landing'
import { CopyButton } from 'beskar/src/dashboard'
import { Kbd } from '@nextui-org/react'
import { LightningBoltIcon } from '@heroicons/react/solid'
import clsx from 'classnames'

import { useSession } from 'next-auth/react'

import { signupAtom } from '@/lib/atoms'
import { KnownError } from '@/lib/errors'
import { usePersistentState } from '@/lib/hooks'
import { createBuyLink } from '@/lib/utils'
import { Select } from 'beskar/src/Select'
import { RangeSlider, Textarea } from 'beskar/src/landing/form'
import classNames from 'classnames'
import { tikTokVoices } from 'db/data'

import { generateComposedAudioUrl } from '@/lib/utils'
import {
    getManageLemonSubscriptionUrl,
    getSubscriptions,
    getUserCredits,
    getUserOrg,
} from '@/pages/api/functions'
import dynamic from 'next/dynamic'
import { useEffect, useRef, useState } from 'react'
import toast from 'react-hot-toast'
import useSWR from 'swr'
import { ModalCloseButton } from '@chakra-ui/react'

const PreviewButton = dynamic(() => import('@/components/PreviewButton'), {
    loading: () => null,
    ssr: false,
}) as any

let langs = Object.keys(tikTokVoices)

function onError(e: any) {
    toast.error(e.message)
}

export function TTSForm({ initialVoice = '' }) {
    const [lang, setLang] = usePersistentState('lang', langs[0])
    const [text, setText] = usePersistentState('text', '')
    const [voice, setVoice] = usePersistentState(
        'voice',
        initialVoice || tikTokVoices[lang]?.[0]?.id,
    )
    let [audioSource, setAudioSource] = usePersistentState('download link', '')
    let audioRef = useRef<HTMLAudioElement>(null)

    useEffect(() => {
        if (!tikTokVoices[lang]?.map((x) => x.id).includes(voice)) {
            setVoice(tikTokVoices[lang]?.[0]?.id)
        }
    }, [lang])

    function play() {
        let playing = !audioRef.current.paused
        if (playing) {
            audioRef.current.pause()
        } else {
            audioRef.current.play()
        }
    }
    let [autoPlay, setAutoPlay] = useState(false)
    const { fn: generateClient, isLoading } = useThrowingFn({
        async fn() {
            if (!text) {
                throw new KnownError('Please enter some text')
            }
            setAutoPlay(true)
            {
                let words = text.split(' ').length
                splitbee.track('generate', {
                    words,
                })
            }
            setAudioSource('')
            try {
                const { url: audioSource } = await generateComposedAudioUrl({
                    text,
                    voice,
                    onPartStarted({ index, total }) {
                        if (total > 1) {
                            setLoadingMessage(
                                `Generating audio part... ${index}/${total}`,
                            )
                        }
                    },
                })
                mutate()
                setAudioSource(audioSource)
                audioRef.current.src = audioSource
                setTimeout(() => play())
            } finally {
                setLoadingMessage('')
            }
        },
        successMessage: '',
    })
    let [errorMessage, setError] = useState('')
    let [loadingMessage, setLoadingMessage] = useState('')
    const { data: session } = useSession()
    let [isPlaying, setIsPlaying] = useState(false)
    const {
        data: credits,
        mutate,
        error,
    } = useSWR('credits', () => getUserCredits(), { onError })
    let progress = credits?.used / credits?.total
    const textarea = useRef<HTMLTextAreaElement>(null)
    useEffect(() => {
        if (textarea.current) {
            textarea.current.focus()
        }
    }, [])
    const [speed, setSpeed] = useState(1)
    useEffect(() => {
        audioRef.current.playbackRate = speed
    }, [speed])
    return (
        <div className='flex flex-col gap-12 w-full items-stretch'>
            <div className='flex flex-col p-6 dark:bg-gray-950 justify-center rounded-lg shadow-xl gap-8 w-full items-center'>
                <form
                    onSubmit={(e) => {
                        e.preventDefault()
                        generateClient()
                    }}
                    id='speech'
                    className='flex flex-col gap-6 w-full grow '
                >
                    {errorMessage && (
                        <div className='text-red-400 text-sm'>
                            {errorMessage}
                        </div>
                    )}
                    <Textarea
                        value={text}
                        ref={textarea}
                        autoResize
                        onChange={(e) => {
                            if (errorMessage) {
                                setError('')
                            }
                            if (loadingMessage) {
                                setLoadingMessage('')
                            }
                            let text = e.target.value
                            setText(text)
                            // if (text.length > MAX_LEN) {
                            //     setError('Text too long, max is 300 characters')
                            // }
                        }}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter' && e.metaKey) {
                                e.preventDefault()
                                generateClient()
                            }
                        }}
                        className='min-h-[160px] !p-4'
                        placeholder='Write text here'
                    />
                    <div
                        className={clsx(
                            'flex flex-col w-full grow md:flex-row gap-4',
                        )}
                    >
                        <Select
                            // useAutoGradientIcons

                            onChange={(e) => {
                                setLang(e)
                            }}
                            value={lang}
                            className={classNames(
                                'z-100 min-w-[16ch] !border-0',
                            )}
                            options={langs.map((k) => {
                                return {
                                    value: k,
                                    name: k,
                                }
                            })}
                        />
                        <Select
                            // useAutoGradientIcons
                            onChange={(e) => {
                                setVoice(e)
                            }}
                            value={voice}
                            className={classNames('min-w-[16ch] !border-0')}
                            options={tikTokVoices[lang]?.map((o) => {
                                return {
                                    value: String(o.id),
                                    whenButton: o.name,
                                    name: (
                                        <div className='flex grow gap-1'>
                                            <div className='grow'>{o.name}</div>
                                            <div className='grow'></div>

                                            <PreviewButton
                                                className='mr-1'
                                                voice={o.id}
                                            />
                                        </div>
                                    ),
                                }
                            })}
                        />
                        {/* <div className='flex gap-3 items-center rounded-md bg-gray-700 px-2'>
                            <div className='text-xs font-mono'>
                                speed: {Number(speed).toFixed(1)}
                            </div>
                            <RangeSlider
                                className=''
                                min={0.5}
                                step={0.1}
                                max={2}
                                defaultValue={1}
                                value={speed}
                                onChange={(e) => {
                                    setSpeed(e.target.valueAsNumber)
                                }}
                            />
                        </div> */}

                        <div className='grow hidden md:block'></div>
                        <Button
                            type='submit'
                            bg='blue.100'
                            // icon={<LightningBoltIcon className='h-4' />}
                            disabled={!text || !!errorMessage}
                            className='font-bold text-sm h-[34px] '
                            isLoading={isLoading}
                        >
                            Generate
                            <Kbd
                                className='ml-2 font-bold text-xs '
                                keys={['command', 'enter']}
                            ></Kbd>
                        </Button>
                    </div>
                    <audio
                        src={audioSource}
                        className='!mt-0 hidden'
                        ref={audioRef}
                        autoPlay={autoPlay}
                        onPlay={() => {
                            setIsPlaying(true)
                        }}
                        onPause={() => {
                            setIsPlaying(false)
                        }}
                    />
                </form>
                {loadingMessage && (
                    <div className='opacity-60'>{loadingMessage}</div>
                )}

                {audioSource && (
                    <div className='flex flex-col items-center'>
                        <div className='flex gap-3'>
                            <Button className='' onClick={play}>
                                {isPlaying ? 'Pause' : 'Play'}
                            </Button>
                            <Button
                                href={audioSource}
                                // @ts-ignore
                                type='audio/mp3'
                                // @ts-ignore
                                download='text-to-speech.mp3' // TODO better name for file
                                onClick={(e) => {
                                    let audio = audioRef.current
                                    audio.pause()
                                    if (!session) {
                                        signupAtom.set(true)
                                        e.preventDefault()
                                        return
                                    }
                                    splitbee.track('download')

                                    // download audio file
                                }}
                            >
                                Download
                            </Button>
                        </div>
                    </div>
                )}
            </div>
            {session && !!progress && (
                <div className='flex flex-col p-6 dark:bg-gray-950 justify-center rounded-lg shadow-xl gap-8 w-full items-center'>
                    <div className='flex-col max-w-full items-center flex gap-3'>
                        <div className='text-center font-medium opacity-80'>
                            Used {credits.used} of {credits.total} words limit{' '}
                            {credits.free && '(free account)'}
                        </div>
                        <div className='flex items-center w-full gap-3'>
                            <ProgressBar
                                className='w-full md:min-w-[400px] text-xs'
                                progress={progress}
                            />
                            <div className='opacity-70 text-sm font-mono'>
                                {Number(Math.min(progress, 1) * 100).toFixed(
                                    0,
                                ) + '%'}
                            </div>
                        </div>

                        <div className='mt-2 flex gap-3'>
                            <BuyButton ghost={progress < 0.5} />
                            <ManagePlan />
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

function BuyButton({ ghost = true }) {
    const { data: session } = useSession()
    let [href, setHref] = useState('')
    let [isLoading, setIsLoading] = useState(false)
    useEffect(() => {
        Promise.resolve().then(async () => {
            const org = await getUserOrg({})
            if (!org) {
                return
            }
            setHref(
                createBuyLink({
                    email: session?.user?.email,
                    orgId: org?.id,
                }),
            )
        })
    }, [])
    if (!href) {
        return null
    }

    return (
        <motion.div
            className='flex gap-4'
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
        >
            <Button
                // ref={(r) => {
                //     try {
                //         // @ts-ignore
                //         window.createLemonSqueezy()
                //     } catch (e) {
                //         console.log('cannot window.createLemonSqueezy', e)
                //     }
                // }}
                ghost={ghost}
                href={href}
                isLoading={isLoading}
                className='lemonsqueezy-button font-bold text-sm'
                disabled={!href}
                target=''
                onClick={() => {
                    splitbee.track('buy intent')
                    setIsLoading(true)
                    setTimeout(() => {
                        setIsLoading(false)
                    }, 1000 * 5)
                }}
            >
                Buy Credits
            </Button>
        </motion.div>
    )
}

function ProgressBar({ progress, className = '' }) {
    const backgroundColor = (() => {
        if (progress > 0.9) {
            return 'bg-orange-500'
        }
        if (progress > 0.6) {
            return 'bg-yellow-400'
        }

        return 'bg-green-500'
    })()
    return (
        <div
            // style={{ backgroundColor }}
            className={classNames(
                'relative rounded-md overflow-hidden w-full bg-gray-700 flex h-[0.8em]',
                className,
            )}
        >
            <motion.div
                // layout
                transition={{ duration: 0.4 }}
                animate={{
                    width: Number(Math.min(progress, 1) * 100).toFixed(0) + '%',
                }}
                className={classNames(
                    'h-full bg-gray-200 rounded overflow-hidden',
                    backgroundColor,
                )}
            ></motion.div>
        </div>
    )
}

function ManagePlan({}) {
    const { data: subs } = useSWR('cancel plan', () =>
        getSubscriptions({ onError }),
    )
    const { fn: manage, isLoading } = useThrowingFn({
        fn: async () => {
            const { url } = await getManageLemonSubscriptionUrl({})
            if (!url) {
                toast.error(`No subscription found`)
                return
            }
            window.location.href = url
            // $crisp.push(['do', 'chat:send'])
        },
    })
    if (!subs) {
        return null
    }
    let sub = subs?.[0]
    if (!sub) {
        return null
    }
    return (
        <motion.div
            className='flex gap-3'
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
        >
            <Button
                isLoading={isLoading}
                ghost
                className='text-sm'
                onClick={manage}
            >
                Manage Subscription
            </Button>
            {/* <InviteUser /> */}
        </motion.div>
    )
}

function InviteUser() {
    const { isOpen, onOpen, onClose, onOpenChange } = useDisclosure()
    const [url, setUrl] = useState('')
    const { fn: create, isLoading } = useThrowingFn({
        async fn() {
            const { url } = await createInviteLinkAuth({})
            setUrl(url)
            onOpen()
        },
    })
    return (
        <>
            <Button
                ghost
                className='text-sm'
                isLoading={isLoading}
                onClick={create}
            >
                Invite Member
            </Button>
            <Modal backdrop='blur' isOpen={isOpen} onOpenChange={onOpenChange}>
                <ModalContent>
                    <>
                        <ModalHeader className='flex flex-col gap-1'>
                            Invite Member
                        </ModalHeader>

                        <ModalBody>
                            <p className=''>
                                Send the following link to invite members and
                                use your subscription
                            </p>
                            <Input
                                className='font-mono'
                                classNames={{ input: 'text-[13px]' }}
                                endContent={
                                    <CopyButton children='Copy' text={url} />
                                }
                                readOnly
                                value={url}
                            />
                        </ModalBody>
                        <ModalFooter>
                            {/* <Button color='primary'>Done</Button> */}
                        </ModalFooter>
                    </>
                </ModalContent>
            </Modal>
        </>
    )
}
